import React from 'react';
import './App.css';
import Routing from './routes';


function App() {
  return (
    <Routing />
  );
}

export default App;
